import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(private http: HttpClientService) {}

  public getAllNotificationsPaginate(page?: number) {
    return this.http.get("notifications", { page });
  }

  public getNotification(notificationId: string) {
    return this.http.post({ id: notificationId }, "notifications/mark-as-read");
  }

  public get markAsRead() {
    return this.http.post("notifications/mark-all-as-read");
  }

  public get totalUnread() {
    return this.http.get("notifications/total-unread-notifications");
  }
  // public get totalUnread() {
  //   return this.http.get("notifications/total-unread");
  // }
}

import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Home",
    isTitle: true,
    role: "admin",
    permission: "view_dashboard_main_page",
  },
  {
    id: 2,
    label: "Home",
    icon: "bx-home-circle",
    link: "/dashboard",
    role: "admin",
    permission: "view_dashboard_main_page",

    // subItems: [
    //   {
    //     id: 3,
    //     label: "MENUITEMS.DASHBOARDS.LIST.DEFAULT",
    //     link: "/home",
    //     parentId: 2,
    //   },
    //   {
    //     id: 4,
    //     label: "System Users",
    //     parentId: 2,
    //     subItems: [
    //       {
    //         id: 7,
    //         label: "List Users",
    //         link: "/users/list",
    //         parentId: 4,
    //       },
    //       {
    //         id: 8,
    //         label: "Add new User",
    //         link: "/users/create",
    //         parentId: 4,
    //       },
    //     ],
    //   },
    // ],
  },
  {
    id: 11,
    label: "Home",
    isTitle: true,
    role: "affiliate",
  },
  {
    id: 12,
    label: "Home",
    icon: "bx-home-circle",
    link: "/affiliatedashboard",
    role: "affiliate",
  },

  {
    id: 13,
    label: "Transactions",
    isTitle: true,
    role: "affiliate",
  },

  {
    id: 14,
    label: "All Transactions",
    link: "/allTransactions",
    icon: "bx-book-open",
    role: "affiliate",
  },

  {
    id: 16,
    label: "Banks",
    isTitle: true,
    role: "affiliate",
  },

  {
    id: 16,
    label: "Banks",
    link: "/bankAccounts",
    icon: "bx bxs-bank",
    role: "affiliate",
  },

  {
    id: 3,
    label: "Home",
    isTitle: true,
    role: "store",
  },
  {
    id: 3,
    label: "Home",
    icon: "bx-home-circle",
    link: "/storedashboard",
    role: "store",
  },
  {
    id: 3,
    label: "Orders",
    isTitle: true,
    role: "store",
  },
  {
    id: 3,
    label: "Orders",
    icon: "bx bx-cart",
    link: "/storeorders",
    role: "store",
  },
  {
    id: 3,
    label: "Offers",
    isTitle: true,
    role: "store",
  },
  {
    id: 3,
    label: "Offers",
    icon: "bx bxs-offer",
    link: "/storeoffers",
    role: "store",
  },
  {
    id: 3,
    label: "Reports",
    isTitle: true,
    role: "store",
  },
  {
    id: 3,
    label: "Operation Reports",
    icon: "bx bx-book-open",
    link: "/storeOperationReports",
    role: "store",
  },

  {
    id: 15,
    label: "Pages",
    isTitle: true,
    role: "admin",
    permission: "view_pages",
  },

  {
    id: 7,
    label: "Pages",
    link: "/pages/list",
    icon: "bx-spreadsheet",
    role: "admin",
    permission: "view_pages",
  },
  {
    id: 8,
    label: "New Page",
    link: "/pages/create",
    icon: "bx-list-plus",
    role: "admin",
    permission: "view_pages",
  },
  // {
  //   id: 17,
  //   label: "System Users",
  //   isTitle: true,
  // },

  // {
  //   id: 18,
  //   label: "List Users",
  //   link: "/users/list",
  //   icon: "bxs-user",
  // },
  // {
  //   id: 19,
  //   label: "Add new User",
  //   link: "/users/create",
  //   icon: "bxs-user-plus",
  // },
  {
    id: 17,
    label: "Support",
    isTitle: true,
    role: "admin",
    permission: "view_support_messages_templates",
  },

  {
    id: 18,
    label: "Terms And Conditions",
    link: "/support",
    queryparams: { currentTap: 1 },
    icon: "bx-support",
    role: "admin",
    permission: "view_support_messages_templates",
  },
  {
    id: 7,
    label: "Offers Terms And Conditions",
    isTitle: true,
    role: "admin",
    permission: "edit_terms_and_conditions",
  },

  {
    id: 7,
    label: "Offers Terms And Conditions",
    link: "/offerterms",
    icon: "bx-shield-alt-2",
    role: "admin",
    permission: "edit_terms_and_conditions",
  },
  {
    id: 9,
    label: "Change Phone Request",
    isTitle: true,
    role: "admin",
    permission: "view_change_phone_requests",
  },

  {
    id: 9,
    label: "Change Phone Request",
    link: "/changephone",
    icon: "bx bx-phone",
    role: "admin",
    permission: "view_change_phone_requests",
  },
  {
    id: 232,
    label: "Display Subscriptions",
    isTitle: true,
    role: "admin",
    permission: "list_users_packages",
  },
  {
    id: 232,
    label: "Display Subscriptions",
    link: "/display-subscriptions",
    icon: "bx bx-user-voice",
    role: "admin",
    permission: "list_users_packages",
  },

  {
    id: 20,
    label: "Regions",
    isTitle: true,
    role: "admin",
    permission: "view_regions",
  },

  {
    id: 21,
    label: "Regions",
    link: "/regions",
    icon: "bx-world",
    role: "admin",
    permission: "view_regions",
  },
  {
    id: 40,
    label: "Nationality",
    isTitle: true,
    role: "admin",
    permission: "view_regions",
  },

  {
    id: 41,
    label: "Nationality",
    link: "/nationality",
    icon: "bx bxs-plane-take-off",
    role: "admin",
    permission: "view_regions",
  },
  {
    id: 121,
    label: "Questions Section",
    isTitle: true,
    role: "admin",
    permission: "view_questions",
  },

  {
    id: 121,
    label: "Questions Section",
    link: "/questions",
    icon: "bx bx-question-mark",
    role: "admin",
    permission: "view_questions",
  },
  {
    id: 65,
    label: "Payments",
    isTitle: true,
    role: "admin",
    permission: "view_payment_settings",
  },

  {
    id: 65,
    label: "Payments",
    link: "/payments",
    icon: "bx bxl-visa",
    role: "admin",
    permission: "view_payment_settings",
  },
  {
    id: 62,
    label: "My Fatorah Payments",
    link: "/payments/my-fatorah",
    icon: "bx bxl-visa",
    role: "admin",
    permission: "list_myfaturah_payments",
  },
  {
    id: 101,
    label: "Orders",
    isTitle: true,
    role: "admin",
    permission: "view_orders",
  },

  {
    id: 101,
    label: "Orders",
    link: "/orders",
    icon: "bx bx-cart",
    role: "admin",
    permission: "view_orders",
  },
  {
    id: 66,
    label: "Mobile Home",
    isTitle: true,
    role: "admin",
    permission: "edit_form_builder",
  },

  {
    id: 66,
    label: "Mobile Home",
    link: "/homeFilter",
    icon: "bx bx-filter-alt",
    role: "admin",
    permission: "edit_form_builder",
  },
  {
    id: 66,
    label: "Offers",
    isTitle: true,
    role: "admin",
    permission: "view_offers",
  },

  {
    id: 66,
    label: "Offers",
    link: "/offers",
    icon: "bx bxs-offer",
    role: "admin",
    permission: "view_offers",
  },
  {
    id: 88,
    label: "Packages",
    isTitle: true,
    role: "admin",
    permission: "view_packages",
  },

  {
    id: 88,
    label: "Packages",
    link: "/packages",
    icon: "bx bx-package",
    role: "admin",
    permission: "view_packages",
  },
  {
    id: 89,
    label: "Users",
    isTitle: true,
    role: "admin",
    permission: "view_clients",
  },

  {
    id: 89,
    label: "Management",
    link: "/management",
    icon: "bx bx-group",
    role: "admin",
    permission: "view_users",
  },
  {
    id: 89,
    label: "Users",
    link: "/users",
    icon: "bx bx-group",
    role: "admin",
    permission: "view_clients",
  },
  {
    id: 89,
    label: "Affiliate",
    link: "/affiliate",
    icon: "bx bx-user-voice",
    role: "admin",
    permission: "view_affiliates",
  },
  {
    id: 90,
    label: "App Intro Images",
    isTitle: true,
    role: "admin",
    permission: "view_sliders",
  },

  {
    id: 90,
    label: "App Intro Images",
    link: "/intro-splash",
    icon: "bx bx bx-images",
    role: "admin",
    permission: "view_sliders",
  },
  {
    id: 99,
    label: "mobileHomeImages",
    link: "/mobilehome",
    icon: "bx bx bx-images",
    role: "admin",
    permission: "view_sliders",
  },
  {
    id: 77,
    label: "Coupons",
    isTitle: true,
    role: "admin",
    permission: "view_coupons",
  },

  {
    id: 77,
    label: "Coupons",
    link: "/coupons",
    icon: "bx bxs-discount",
    role: "admin",
    permission: "view_coupons",
  },
  {
    id: 77,
    label: "Generate Coupons",
    link: "/generate-coupons",
    icon: "bx bxs-discount",
    role: "admin",
    permission: "view_promo_codes",
  },
  {
    id: 78,
    label: "Voucher",
    isTitle: true,
    role: "admin",
    permission: "view_vouchers",
  },

  {
    id: 78,
    label: "Voucher",
    link: "/vouchers",
    icon: "bx bxs-discount",
    role: "admin",
    permission: "view_vouchers",
  },
  {
    id: 78,
    label: "Roles",
    isTitle: true,
    role: "admin",
    permission: "view_privileges",
  },

  {
    id: 78,
    label: "Roles",
    link: "/roles",
    icon: "bx bx-lock-alt",
    role: "admin",
    permission: "view_privileges",
  },

  {
    id: 51,
    label: "Banks",
    isTitle: true,
    role: "admin",
    permission: "view_banks",
  },

  {
    id: 51,
    label: "Banks",
    link: "/bank",
    icon: "bx bxs-bank",
    role: "admin",
    permission: "view_banks",
  },
  {
    id: 67,
    label: "Join Request",
    isTitle: true,
    role: "admin",
    permission: "view_join_requests",
  },

  {
    id: 67,
    label: "Join Request",
    link: "/joinrequests",
    icon: "bx bxs-bank",
    role: "admin",
    permission: "view_join_requests",
  },
  {
    id: 20,
    label: "Categories",
    isTitle: true,
    role: "admin",
    permission: "view_categories",
  },

  {
    id: 21,
    label: "Categories",
    link: "/categories",
    icon: "bx-book-open",
    role: "admin",
    permission: "view_categories",
  },

  // {
  //   id: 27,
  //   label: "Device",
  //   isTitle: true,
  // },
  // {
  //   id: 28,
  //   label: "Devices",
  //   icon: "bx bx-devices",
  //   link: "/devices",
  // },
  {
    id: 29,
    label: "Stores",
    isTitle: true,
    role: "admin",
    permission: "view_stores",
  },
  {
    id: 30,
    label: "Stores",
    icon: "bx-store-alt",
    link: "/stores",
    role: "admin",
    permission: "view_stores",
  },
  {
    id: 32,
    label: "Online Stores",
    isTitle: true,
    role: "admin",
    permission: "view_online_stores",
  },
  {
    id: 32,
    label: "Online Stores",
    icon: "bx-store-alt",
    link: "/online-store",
    role: "admin",
    permission: "view_online_stores",
  },
  {
    id: 22,
    label: "Services",
    isTitle: true,
    role: "admin",
    permission: "view_services",
  },
  {
    id: 23,
    label: "Services",
    icon: "bx-wrench",
    link: "/services",
    role: "admin",
    permission: "view_services",
  },
  {
    id: 106,
    label: "Reports",
    isTitle: true,
    role: "admin",
    permission: "view_reports",
  },
  {
    id: 151,
    label: "Reports",
    role: "admin",
    permission: "view_reports",

    subItems: [
      {
        id: 105,
        label: "Users Report",
        link: "/reports/usersReport",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 106,
        label: "Daily Report",
        link: "/reports/dailyoperation",
        icon: "bx bxs-report",
        queryparams: { today: 1 },
        parentId: 151,
      },
      {
        id: 107,
        label: "Monthly Report",
        link: "/reports/monthlyoperation",
        icon: "bx bxs-report",
        queryparams: { month: 1 },
        parentId: 151,
      },
      {
        id: 108,
        label: "Operation Reports",
        link: "/reports/operationReports",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 109,
        label: "Offers Reports",
        link: "/reports/genericReports",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 110,
        label: "Order Daily Report",
        link: "/reports/dailyorderreport",
        icon: "bx bxs-report",
        queryparams: { today: 1 },
        parentId: 151,
      },
      {
        id: 111,
        label: "Order monthly Report",
        link: "/reports/monthlyorderreport",
        icon: "bx bxs-report",
        queryparams: { month: 1 },
        parentId: 151,
      },
      {
        id: 112,
        label: "Order Report",
        link: "/reports/orderreport",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 112,
        label: "Detailed Order Report",
        link: "/reports/genericorderreport",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 112,
        label: "Nationalities Report",
        link: "/reports/nationalitiesreport",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 112,
        label: "Age Report",
        link: "/reports/agesreport",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 112,
        label: "Claimed Offer Reports",
        link: "/reports/claimedoffer",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 112,
        label: "Order Analysis",
        link: "/reports/countbydate",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 112,
        label: "Stores Report",
        link: "/reports/storereport",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 113,
        label: "Online Stores",
        link: "/reports/onlineStoresReport",
        icon: "bx bxs-report",
        parentId: 151,
      },
      {
        id: 114,
        label: "Stores Location",
        link: "/reports/storesLocationReport",
        icon: "bx bxs-report",
        parentId: 151,
      },
    ],
  },
  {
    id: 31,
    label: "Announcment",
    isTitle: true,
    role: "admin",
    permission: "view_ads",
  },
  {
    id: 32,
    label: "Announcment",
    icon: "bx-volume-full",
    link: "/announcment",
    role: "admin",
    permission: "view_ads",
  },

  {
    id: 201,
    label: "Notification",
    isTitle: true,
    role: "admin",
    permission: "view_notifications",
  },
  {
    id: 201,
    label: "Notification",
    icon: "bx bx-bell",
    link: "/notification",
    role: "admin",
    permission: "view_notifications",
  },
  {
    id: 5,
    label: "Settings",
    isTitle: true,
    role: "admin",
    permission: "view_settings",
  },
  {
    id: 6,
    label: "Settings",
    icon: "bx bxs-cog",
    link: "/settings",
    role: "admin",
    permission: "view_settings",
  },
];

import { MessageService } from "primeng/api";
import { MessagingService } from "./core/services/notification/notification.service";
import { NavigationStart, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Component, OnInit } from "@angular/core";

export let browserRefresh = false;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [MessageService],
})
export class AppComponent implements OnInit {
  subscription: Subscription;

  constructor(
    private router: Router,
    private messagingService: MessagingService,
    private popupService: MessageService
  ) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }
  ngOnInit() {
    this.messagingService.requestPermission();

    this.messagingService.currentMessage.subscribe((msg) => {
      if (msg) {
        // Notifications on start
        this.popupService.add({
          severity: "success",
          summary: msg.notification.title,
          detail: msg.notification.body,
          life: 6000,
        });
      }
    });

    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}
